import React from "react";

import {
  Box,
  Button,
  Container,
  Flex,
  Icon,
  Text,
  chevronLeft,
} from "../components";

import { Layout } from "../patterns";

const NotFoundPage = () => (
  <Layout>
    <Box
      $bgColor="background.dark"
      $marginBottom={8}
      $paddingY={10}
      $paddingX={5}
    >
      <Container>
        <Flex $flexDirection="column" $alignItems="center" $gap={5}>
          <Text
            as="h1"
            $fontSize="heading2"
            $fontFamily="heading"
            $fontWeight="900"
            $textTransform="uppercase"
          >
            Well, this is awkward!
          </Text>
          <Text as="p" $maxWidth="560px">
            We can't find the page you're looking for.
          </Text>

          <Button as="a" $appearance="secondary" href="/">
            <Icon
              src={chevronLeft}
              aria-hidden
              $marginRight={2}
              $marginLeft={-3}
            />
            Return to Homepage
          </Button>
        </Flex>
      </Container>
    </Box>
  </Layout>
);

export default NotFoundPage;
